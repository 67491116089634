import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const Gallery = () => {
  return (
    <Layout className="section-gallery">
      <Seo title="Gallery" />
      <div className="container section-gallery__gallery">
        <div className="section-gallery__gallery__overview row g-3">
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-1.jpg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-2.jpg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-3.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-4.jpg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-5.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-6.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-7.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-8.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-9.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-10.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-11.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-12.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-13.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-14.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-15.jpeg"
              className="w-100"
              alt="Image"
            />
          </div>
          <div className="col-6 col-lg-3">
            <StaticImage
              src="../assets/images/gallery-16.jpg"
              className="w-100"
              alt="Image"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Gallery
